import axios from 'axios';

import { API_URL } from '../../config/default';
import { useAuth } from '../auth';
import { useError } from "../error";

export function Event () {
  const errors = useError();
  const { token } = useAuth();

  const getEvents = async (search, orderBy, order, page, filter) => {
    errors.clearErrors();

    return axios.get(API_URL + 'event', {
      params: {
        token: token,
        search: search,
        filter: typeof filter === "undefined"?[]:filter,
        page: page,
        order_by: orderBy,
        order: order
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
    });
  }

  const getEvent = async (id) => {
    errors.clearErrors();

    return axios.get(`${API_URL}event/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return [];
    });
  }

  const deleteEvent = async (id) => {
    errors.clearErrors();

    return axios.delete(`${API_URL}event/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres törlés!'
      }]);
      
      return true;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return false;
    });
  }

  const saveEvent = async (id, data, onSuccess) => {
    errors.clearErrors();

    let requestURI = `${API_URL}event`;

    if(typeof id !== "undefined") requestURI += `/${id}`;

    return axios.post(
      requestURI,
      {
        token: token,
        ...data
      }
    )
    .then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres mentés!'
      }]);

      onSuccess(response.data);

      return true;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return false;
    });
  }

  const addOrganizer = async (id, data, onSuccess) => {
    errors.clearErrors();
    
    return axios.post(
      `${API_URL}event/${id}/add-organizer`,
      {
        token: token,
        organizer_id: data.id,
        title: data.title
      }
    )
    .then(response => {
      onSuccess(response.data);

      return true;
    })
    .catch(error => {
      errors.updateErrors(error.response.data);

      return false;
    });
  }

  const getJoinData = async (eventId, userId) => {
    return axios.get(
      `${API_URL}event/${eventId}/join-data`,
      {
        params: {
          token: token,
          user_id: userId
        }
      }
    ).then(response => {
      return response.data;
    }).catch(error => {
      //errors.updateErrors(error.response.data);
      return {};
    });
  }

  return {
    getEvents,
    getEvent,
    deleteEvent,
    saveEvent,
    addOrganizer,
    getJoinData
  }
}