import axios from 'axios';

import { API_URL } from '../../config/default';
import { useAuth } from '../auth';
import { useError } from "../error";

export function Organizer () {

  const errors = useError();
  const { token } = useAuth();

  const getOrganizers = async (search, event_id, event_exclude_id, page) => {
    errors.clearErrors();

    return axios.get(`${API_URL}organizer`, {
      params: {
        token: token,
        search: search??"",
        filter: {
          event_id: event_id,
          event_exclude_id: event_exclude_id
        },
        page: page??1
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
    });
  }

  const getOrganizer = async (id) => {
    errors.clearErrors();

    return axios.get(`${API_URL}organizer/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
    });
  }

  const deleteOrganizer = async (id) => {}

  const saveOrganizer = async (id, data, onSuccess) => {
    errors.clearErrors();

    let requestURI = `${API_URL}organizer`;

    if(typeof id !== "undefined" || id === null) requestURI += `/${id}`;

    return axios.post(
      requestURI,
      {
        token: token,
        ...data
      }
    )
    .then(response => {
      onSuccess(response.data);

      return true;
    })
    .catch(error => {
      errors.updateErrors(error.response.data);

      return false;
    });
  }

  return {
    getOrganizers,
    getOrganizer,
    deleteOrganizer,
    saveOrganizer
  }
}