import axios from 'axios';

import { API_URL } from '../../config/default';
import { useAuth } from '../auth';
import { useError } from "../error";

export function User () {

  const errors = useError();
  const { token } = useAuth();

  const getUsers = async (search, page) => {
    errors.clearErrors();

    return axios.get(`${API_URL}user`, {
      params: {
        token: token,
        search: search??"",
        filter: {},
        page: page??1
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
    });
  }

  const get = async (id) => {
    errors.clearErrors();

    return axios.get(`${API_URL}user/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      
      return {};
    });
  }

  const remove = async (id) => {
    errors.clearErrors();

    return axios.delete(`${API_URL}user/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres törlés!'
      }]);
      
      return true;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return false;
    });
  }

  const save = async (id, data, onSuccess) => {
    errors.clearErrors();

    let requestURI = `${API_URL}user`;

    if(typeof id !== "undefined" || id === null) requestURI += `/${id}`;

    return axios.post(
      requestURI,
      {
        token: token,
        ...data
      }
    )
    .then(response => {
      onSuccess(response.data);

      return true;
    })
    .catch(error => {
      errors.updateErrors(error.response.data);

      return false;
    });
  }

  return {
    getUsers,
    get,
    remove,
    save
  }
}