import React, { useState, useContext, createContext, useEffect } from "react";
import axios from 'axios';
import useToken from "./useToken";
import { API_URL } from '../../config/default';
import { useError } from "../error";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const { token, setToken } = useToken();
  const errors = useError();
  
  useEffect(function(){
    if (!user && token) {
      axios.get(API_URL + "user/self?token="+token).then(function (userResponse){
        if(userResponse.status === 200) {
          setUser(userResponse.data);
          return true;
        } else {
          // Error in user data request
          // TODO: Error Handling
          
          return false;
        }
        
      }).catch(function (error){
        console.log(error);
        setToken(null);
        return false;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  
  const verify = () => {
    // TODO: Verify token through API

    return token !== null;
  }

  const signin = async (email, password) => {
    // TODO: API authentication
    return axios.post(
      API_URL + "user/login",
      {
        type: 'EMAIL',
        admin_login: true,
        email: email,
        password: password
      }
    )
    .then(function (response){
      // Succesful authentication
      if (response.status === 200){
        // Get logged in user data from server
        return axios.get(API_URL + "user/self?token="+response.data.token).then(function (userResponse){
          if(userResponse.status === 200) {
            setToken(response.data.token);
            setUser(userResponse.data);
            return true;
          } else {
            // Error in user data request
            // TODO: Error Handling
            
            return false;
          }
          
        }).catch(function (error){
          console.log(error);
          return false;
        });

      // Error in authentication
      } else {
        // TODO: Error Handling
        console.log(response);

        return false;
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.response.status === 400) {
        errors.updateErrors(error.response.data);
      } else {

      }
      return false;
    });
  };
  
  const signout = () => {
    // TODO: Send Token Invalidate Request to API
    
    setToken(null);
    setUser(null);

    return true;
  };
  const sendPasswordResetEmail = (email) => {
    // TODO: Send API request password reset
    
    return false;
  };
  const confirmPasswordReset = (code, password) => {
    // TODO: Send API confirm password reset

    return true;
  };

  // Return the user object and auth methods
  return {
    user,
    token,
    verify,
    signin,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}