import React, { useState, useContext, createContext } from "react";

const errorContext = createContext();

export function ProvideError({ children }) {
  const error = useProvideError();
  return <errorContext.Provider value={error}>{children}</errorContext.Provider>;
}

export const useError = () => {
  return useContext(errorContext);
};

function useProvideError() {
  const [errors, setErrors] = useState([]);
  const errorLevels = {
    'SUCCESS': 'success',
    'ERROR': 'danger',
    'WARNING': 'warning',
    'INFO': 'info'
  };
  

  const addError = (error) => {
    error["level"] = errorLevels[error["level"]]??errorLevels["ERROR"];

    setErrors([
      ...errors,
      error
    ]);
  }

  const updateErrors = (errors) => {
    if(errors["message"]) {
      setErrors([
        {
          "level": errorLevels["ERROR"],
          "message": errors["message"]
        }
      ]);
    } else {
      setErrors(errors.map(function (errorElement) { 
        errorElement["level"] = errorLevels[errorElement["level"]]??errorLevels["ERROR"];
        return errorElement;
      }));
    }
    //setErrors(errors);
  }

  const removeError = (error) => {
    setErrors(errors.filter(errorEl => { return errorEl !== error }));
  }

  const clearErrors = () => {
    setErrors([]);
  }

  const getError = (fieldName) => {
    return errors.find(error => {
      return error.field_name === fieldName;
    });
  }

  const getAlerts = () => {
    return errors.filter(error => {
      return !error.field_name;
    })
  }

  return {
    errors,
    addError,
    updateErrors,
    removeError,
    clearErrors,
    getError,
    getAlerts
  };
}