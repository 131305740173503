import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import { ProvideAuth } from './components/auth';
import { ProvideError } from './components/error';
import App from './components/app/App';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ProvideAPI } from './components/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProvideError>
      <ProvideAuth>
        <ProvideAPI>
          <Suspense fallback={<p>Töltés</p>}>
            <App />
          </Suspense>
        </ProvideAPI>
      </ProvideAuth>
    </ProvideError>
  </React.StrictMode>
);