import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../assets/style/App.css';
import '@fortawesome/fontawesome-free/css/all.css'
import { useAuth } from '../auth';

// Pages
const Login = React.lazy(() => import('../pages/Login'))
const Layout = React.lazy(() => import('../layout/Layout'))

function App() {
  const auth = useAuth();

  if(!auth.verify()) {
    return <Login />
  } else {
    //auth.signout();
  }

  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Suspense fallback={<p>Töltés</p>}>
          <Layout/>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
