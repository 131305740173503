import axios from 'axios';

import { API_URL } from '../../config/default';
import { useAuth } from '../auth';
import { useError } from "../error";

export function Activity () {
  const errors = useError();
  const { token } = useAuth();

  const getActivities = async (eventId, userId) => {
    
    let params = {
      token: token,
      event_id: eventId,
      page: 0
    };

    if (typeof userId !== "undefined") params.user_id = userId

    return axios.get(API_URL + 'activity', {
      params: params
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return [];
    });
  }

  const add = async (eventId, userId, activityData, onSuccess) => {
    errors.clearErrors();

    return axios.post(
      `${API_URL}activity`,
      {
        token: token,
        event_id: eventId,
        user_id: userId,
        ...activityData
      }
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres aktivitás felvétel!'
      }]);

      if (typeof onSuccess !== "undefined") onSuccess(response.data);

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return {};
    });
  }

  return {
    getActivities,
    add
  }
}