import axios from 'axios';

import { API_URL } from '../../config/default';
import { useAuth } from '../auth';
import { useError } from "../error";

export function EventCategory () {

  const errors = useError();
  const { token } = useAuth();

  const getEventCategories = async (eventId) => {
    errors.clearErrors();

    return axios.get(API_URL + 'event-category', {
      params: {
        event_id: eventId
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
    });
  }

  const getEventCategory = async (id) => {}

  const deleteEventCategory = async (id) => {
    errors.clearErrors();

    return axios.delete(`${API_URL}event-category/${id}`, {
      params: {
        token: token
      }
    }).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres törlés!'
      }]);
      
      return true;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return false;
    });
  }

  const saveEventCategory = async (id, data, onSuccess) => {
    errors.clearErrors();

    let requestURI = `${API_URL}event-category`;

    if(typeof id !== "undefined" || id === null) requestURI += `/${id}`;

    return axios.post(
      requestURI,
      {
        token: token,
        ...data
      }
    )
    .then(response => {
      onSuccess(response.data);

      return true;
    })
    .catch(error => {
      errors.updateErrors(error.response.data);

      return false;
    });
  }

  return {
    getEventCategories,
    getEventCategory,
    deleteEventCategory,
    saveEventCategory
  }
}