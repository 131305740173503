export const API_URL = 'https://api.magadertsportolj.hu/';
//export const API_URL = 'http://localhost:8000/';

export const dateConfig = {
  day: "2-digit",
  year: "numeric",
  month: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};

export const joinTypes = [
  {
    name: "Egyéni",
    value: "SINGLE"
  },
  {
    name: "Csapat",
    value: "TEAM"
  },
  {
    name: "Mindegyik",
    value: "ALL"
  }
];

export const allowedJoinTypes = ["SINGLE", "TEAM", "ALL"];

export const targetTypes = [
  {
    name: "Távolság",
    value: "DISTANCE",
    unit: "méter"
  },
  {
    name: "Lépésszám",
    value: "STEPS",
    unit: "lépés"
  }
];

export const allowedTargetTypes = ["DISTANCE", "STEPS"];